import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~4],
		"/auth/login": [~5],
		"/auth/login/firm": [~6],
		"/auth/sign-up": [~7],
		"/auth/sign-up/firm": [~8],
		"/firm": [~9,[2]],
		"/firm/account-details": [~10,[2]],
		"/firm/contact-support": [11,[2]],
		"/firm/matter/new": [~22,[2]],
		"/firm/matter/[id]": [~12,[2]],
		"/firm/matter/[id]/assets/[asset_category]/add": [~14,[2]],
		"/firm/matter/[id]/assets/[asset_category]/[asset_id]": [~13,[2]],
		"/firm/matter/[id]/beneficiaries/add": [~16,[2]],
		"/firm/matter/[id]/beneficiaries/[beneficiary_id]": [~15,[2]],
		"/firm/matter/[id]/client": [~17,[2]],
		"/firm/matter/[id]/deceased-details": [~18,[2]],
		"/firm/matter/[id]/details-of-applicant": [~19,[2]],
		"/firm/matter/[id]/liabilities/[asset_category]/add": [~21,[2]],
		"/firm/matter/[id]/liabilities/[asset_category]/[asset_id]": [~20,[2]],
		"/firm/settings": [~23,[2]],
		"/firm/settings/member/add": [~24,[2]],
		"/home": [~25,[3]],
		"/home/assets/[asset_category]/add": [~27,[3]],
		"/home/assets/[asset_category]/[asset_id]": [~26,[3]],
		"/home/beneficiaries/add": [~29,[3]],
		"/home/beneficiaries/[beneficiary_id]": [~28,[3]],
		"/home/contact-support": [30,[3]],
		"/home/liabilities/[asset_category]/add": [~32,[3]],
		"/home/liabilities/[asset_category]/[asset_id]": [~31,[3]],
		"/home/tasks/immediate-arrangements/access-to-joint-bank-accounts": [33,[3]],
		"/home/tasks/immediate-arrangements/applying-for-the-death-certificate": [~34,[3]],
		"/home/tasks/immediate-arrangements/paying-for-the-funeral": [35,[3]],
		"/home/tasks/immediate-arrangements/practical-considerations": [36,[3]],
		"/home/tasks/immediate-arrangements/securing-existing-assets": [37,[3]],
		"/home/tasks/notifying-authorities-and-service-providers/cancelling-unnecessary-expenses-and-freezing-bank-accounts": [38,[3]],
		"/home/tasks/notifying-authorities-and-service-providers/notifying-insurance-companies": [39,[3]],
		"/home/tasks/notifying-authorities-and-service-providers/notifying-pension-providers": [40,[3]],
		"/home/tasks/notifying-authorities-and-service-providers/notifying-the-department-of-social-welfare": [41,[3]],
		"/home/tasks/notifying-authorities-and-service-providers/returning-car-insurance-disks": [42,[3]],
		"/home/tasks/post-grant-of-probate/financial-planning-for-your-future": [43,[3]],
		"/home/tasks/post-grant-of-probate/settling-taxation-for-the-deceased": [44,[3]],
		"/home/tasks/post-grant-of-probate/understanding-tax": [45,[3]],
		"/home/tasks/providing-estate-details/assets-of-the-estate": [~46,[3]],
		"/home/tasks/providing-estate-details/details-of-beneficiaries": [~47,[3]],
		"/home/tasks/providing-estate-details/details-of-the-deceased": [~48,[3]],
		"/home/tasks/providing-estate-details/liabilities-of-the-estate": [~49,[3]],
		"/home/tasks/providing-estate-details/notify-your-solicitor-and-next-steps": [50,[3]],
		"/home/tasks/providing-estate-details/your-details": [~51,[3]],
		"/home/tasks/understanding-the-process/duties-of-the-executor": [52,[3]],
		"/home/tasks/understanding-the-process/how-to-search-for-assets-and-liabilities": [53,[3]],
		"/home/tasks/understanding-the-process/identification-documents": [~54,[3]],
		"/home/tasks/understanding-the-process/proof-of-address": [~55,[3]],
		"/home/tasks/understanding-the-process/the-probate-process": [56,[3]],
		"/home/waiting": [57,[3]],
		"/privacy-policy": [58],
		"/terms-and-conditions": [59]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';